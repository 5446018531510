import React from "react";
import { NavLink } from 'react-router-dom';

const SidebarNav = ({ menuItems, overrideClasses }) => {

  const renderMenuItem = (item) => (
    <li key={item.id} className="usa-sidenav__item">
      <NavLink 
        to={item.url}
        end
        className={props =>
          props.isActive && item.url ? " usa-current" : ""
        }
      >
        {item.name}
      </NavLink>
      {item.subMenu && (
        <ul className="usa-sidenav__sublist">
          {item.subMenu.map((subItem) => renderMenuItem(subItem))}
        </ul>
      )}
    </li>
  );

  return (
    <div data-testid="sidebar-container" className={`${overrideClasses ? overrideClasses : "tablet:grid-col-4 margin-bottom-4 tablet:margin-bottom-0 margin-top-4"}`}>
      <nav aria-label="Side navigation">
        <ul className="usa-sidenav">
          {menuItems.map((item) => renderMenuItem(item))}
        </ul>
      </nav>
    </div>
  );
};

export default SidebarNav