import React, { useEffect, useState } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import PrimaryNav from '../../components/PrimaryNav/PrimaryNav';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import SidebarNav from '../../components/SidebarNav/SidebarNav';
import { ROUTES } from '../../helpers/menus/routeConstants';

import { menuItems } from '../../helpers/menus/docsSidebarMenuItems'

import "./Docs.css"
import Markdown from 'markdown-to-jsx';

const sectionNames = {
  "api-overview": "API Overview",
  "documentation": "Documentation",
  "get-started": "Getting Started",
  "change-history": "Change History",
  "help-and-support": "API Help & Support"
}

const apiName = {
  "dex-uploadapi": "DEX Upload API"
}

const TableRenderer = ({ children }) => {
  const table = <table className='usa-table'>{children}</table>;

  const container = document.createElement('div');
  
  container.innerHTML = ReactDOMServer.renderToString(table);

  const newTable = container.querySelector("table");

  // We go one row at a time to see if the value next to it is blank.  If so merge the two cells
  for (let row of newTable.rows) {
    let i = 0;
    while (i < row.cells.length) {
      if (row.cells[i].innerText === "") {
        row.cells[0].colSpan++;
        row.cells[i].remove();
        row.cells[0].className="center-cell"
      } else {
        i++;
      }
    }
  }
  // We do columns second.  Check the value above and below the cell, merge them if they are the same values.
  let headerCell = null;
  for (let row of newTable.rows) {
    if (row.cells.length > 1){
      const firstCell = row.cells[1];
      if (headerCell === null || firstCell.innerText !== headerCell.innerText) {
        headerCell = firstCell;
      } else {
        headerCell.rowSpan++;
        firstCell.remove();
      }  
    }
  }

  const modifiedHTML = container.innerHTML;

  return <div dangerouslySetInnerHTML={{ __html: modifiedHTML }} />;
};


const MarkdownDocs = () => {
  const { slug, section } = useParams();
  const [content, setContent] = useState('');
  let breadcrumbs = [
    {name: "Home", link: "/"},
    {name: "API Library", link: ROUTES["API Library"]},
    {name: apiName[slug], link: `/docs/${slug}/api-overview`},
    {name: sectionNames[section]}
  ]

  useEffect(() => {
    import(`../../api-docs/${slug}/${section}.md`)
      .then((file) => {
        fetch(file.default)
          .then((res) => res.text())
          .then((text) => setContent(text))
          .catch((err) => setContent("Error loading documentation"))
      })
      .catch((err) => setContent('File not found'))
  }, [slug, section])

  return(
    <>
      <PrimaryNav />
      <main>
      <Breadcrumbs crumbs={breadcrumbs} />
      <div className="grid-row grid-gap padding-x-5">
        <SidebarNav menuItems={menuItems} />
        <div className="tablet:grid-col-6 markdown">
          <Markdown
            options={{
              overrides: {
                table: {
                  component: TableRenderer,
                  props: {
                    className: 'usa-table',
                  },
                },
              },
            }}
          >
            {content}
          </Markdown>
        </div>
      </div>
      </main>
    </>
  )
}

export default MarkdownDocs;
