import { ROUTES } from "./routeConstants";

export const menuItems =
[
  {
    id: 1,
    name: "API Library",
    url: ROUTES["API Library"],
    subMenu: [
      {
        id: 11,
        name: "DEX Upload API",
        subMenu: [
          {
            id: 111,
            name: "API Overview",
            url: `/docs/dex-uploadapi/api-overview`
          },
          {
            id: 112,
            name: "Documentation",
            url: `/docs/dex-uploadapi/documentation`,
            subMenu: [
              {
                id: 1121,
                name: "Swagger Documentation",
                url: `/docs/dex-uploadapi/documentation/swagger`,
              },
            ],
          },
          {
            id: 114,
            name: "Getting Started",
            url: `/docs/dex-uploadapi/get-started`
          },
          {
            id: 115,
            name: "Change History",
            url: `/docs/dex-uploadapi/change-history`
          },
          {
            id: 116,
            name: "API Help & Support",
            url: `/docs/dex-uploadapi/help-and-support`
          }
        ]
      },
      {
        id: 12,
        name: "NIO Autocoder",
        subMenu: [
          {
            id: 121,
            name: "Purpose",
            url: `/docs/nio-autocoder/purpose`
          },
          {
            id: 122,
            name: "Access",
            url: `/docs/nio-autocoder/access`,
          },
          {
            id: 123,
            name: "Guidance",
            url: `/docs/nio-autocoder/guidance`,
            subMenu: [
              {
                id: 1231,
                name: "Swagger Documentation",
                url: `/docs/nio-autocoder/api`,
              },
            ],
          },
          {
            id: 124,
            name: "Changelog",
            url: `/docs/nio-autocoder/CHANGELOG`
          }
        ]
      }
    ]
  }
]